import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import coolhead_logo from '../assets/coolhead_logo.png';
import coolhead_cleber from '../assets/coolhead_cleber.jpg';
import lane4_logo from '../assets/lane4_logo.png';
import lane4_frota from '../assets/lane4_frota.jpeg';
import iporanga_logo from '../assets/iporanga_logo.webp';
import iporanga_roy from '../assets/iporanga_roy.jpeg';
import puuninki_logo from '../assets/puuninki_logo.png';
import puuninki_heidi from '../assets/puuninki_heidi.png';

const userTestimonials=[
  {
    avatar:<Avatar alt="Cleber Gonçalves" src={coolhead_cleber}/>,
    name:'Cleber Gonçalves',
    occupation:'CEO - CoolHead Brew',
    testimonial:'"Partnering with Coda Engineering transformed our sales process. Their tailored platform allowed us to drop outdated legacy systems, leading to significant time savings and improved data accuracy. We can now run BI reports that have driven better business decisions."',
    logo:coolhead_logo,
    logoStyle:{width:'100%',display:'block',marginLeft:'auto',marginRight:'auto'},
    company:'CoolHead Brew',
    link:'https://coolhead.fi/'
  },
  {
    avatar:<Avatar alt="Guilherme Reis" src={lane4_frota}/>,
    name:'Guilherme Reis',
    occupation:'CEO - Lane 4',
    testimonial:'"Coda Engineering provided invaluable guidance for our digital strategy. Their expertise helped us choose the right digital path, enhancing how we connect with clients. Their ongoing support has been instrumental in boosting our operation, making them a trusted partner for our growth."',
    logo:lane4_logo,
    logoStyle:{width:'30%',display:'block',marginLeft:'auto',marginRight:'auto'},
    company:'Lane 4',
    link:'https://www.lane4.fi/'
  },
  {
    avatar:<Avatar alt="Robinson Gamba Dantas" src={iporanga_roy} />,
    name:'Robinson Gamba Dantas',
    occupation:'Board Member - Iporanga Ventures',
    testimonial:'"Coda Engineering expertly designed and built two CRM platforms tailored to our needs. The financial CRM empowers clients to monitor positions and manage funds, while the real estate CRM enables our sales team to perform quick credit simulations. Their work has significantly enhanced our efficiency across both sectors."',
    logo:iporanga_logo,
    logoStyle:{width:'50%',display:'block',marginLeft:'auto',marginRight:'auto'},
    company:'Iporanga Ventures',
    link:'https://www.ipo.ventures/'
  },
  {
    avatar:<Avatar alt="Heidi Inkeri Nurmi" src={puuninki_heidi} />,
    name:'Heidi Inkeri Nurmi',
    occupation:'Owner - Puuninki',
    testimonial:'"Working with Coda Engineering was a game-changer for our business. Their professionalism, service quality, and unwavering support stood out as they developed a tailor made resource management web application that perfectly matched our needs. They streamlined processes, eliminated manual paperwork, and delivered beyond expectations. Highly recommended for innovative solutions and exceptional attention to detail!"',
    logo:puuninki_logo,
    logoStyle:{width:'45%',display:'block',marginLeft:'auto',marginRight:'auto'},
    company:'Puuninki',
    link:'https://www.puuninki.fi/'
  },
];


export default function Testimonials() {
  return (
    <Container
      id="testimonials"
      style={{paddingTop:'10px'}}
      sx={{
        pt:{xs:8,sm:16},
        pb:{xs:8,sm:16 },
        backgroundImage:`linear-gradient(rgba(9, 14, 16, 0),#02294F)`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        position:'relative',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        gap: {xs:3,sm:6},
      }}>
      <Box
        sx={{
          width:{xs:'100%',sm:'100%',md:'60%'},
          textAlign:{xs:'center',sm:'center',md:'center'},
        }}>
        <Typography component="h2" variant="h4" color="text.primary">
          Testimonials
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Here's what our partners have to say about their experiences with Coda Engineering. These testimonials highlight the impactful solutions and counsel we've delivered across various industries.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial,index)=>(
          <Grid item xs={12} sm={6} md={4} key={index} sx={{display:'flex'}}>
            <Card
              sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
                flexGrow:1,
                p:1,
                bgcolor:'#031524'
              }}>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display:'flex',
                  flexDirection:'row',
                  justifyContent:'space-between',
                  pr:2,
                }}>
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}/>
              </Box>
              <a href={testimonial.link} target="_blank">
              <img
                  src={testimonial.logo}
                  alt={testimonial.company}
                  style={testimonial.logoStyle}/>
              </a>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}